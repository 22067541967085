<template>
  <div>
    <div v-if="type == 'DiscountMapObject'">
      <div v-bind:class="`object-info ${sizeClass}`">
        <rooms-count v-bind="roomsCountBind"></rooms-count>
        <property-area v-bind="propertyAreaBind"></property-area>
        <property-price v-bind="propertyPriceBind"></property-price>
      </div>
      <short-property-info-with-comma v-bind:address="address"
                                      v-bind:flatInfo="flatInfoWithUnit"
                                      v-bind:constructionYear="constructionYearInner"
                                      v-bind:materialType="materialTypeInner">
      </short-property-info-with-comma>
    </div>
    <div v-if="type == 'ObjectCard'">
      <tags v-if="tags && !isMobile && estimationResultsIsVisible"
            class="text-xs text-muted tags"
            v-bind:tags="tags">
      </tags>
      <div v-bind:class="`object-info ${sizeClass}`">
        <rooms-count v-bind="roomsCountBind"></rooms-count>
        <property-area v-bind="propertyAreaBind"
                       v-bind:hideDivider="true"
                       v-bind:showComma="true"></property-area>
        <building-rating v-bind:buildingRating="buildingRating"
                         v-bind:sizeClass="sizeClass"
                         v-bind:hideDivider="true"
                         v-bind:showComma="true"
                         v-bind:coordinate="coordinate"></building-rating>
        <noise-area v-bind:inNoiseArea="inNoiseArea"
                    v-bind:sizeClass="sizeClass"
                    v-bind:hideDivider="true"
                    v-bind:showComma="!buildingRating"
                    class="noise-area"></noise-area>
        <exposition-days v-bind:sizeClass="sizeClass"
                         v-if="expositionDays"
                         v-bind:expositionDays="expositionDays"></exposition-days>
      </div>
      <div class="d-flex justify-content-between align-items-baseline">
        <repair-rating v-bind:repairRating="repairRatingChangesTo || repairRating"
                      v-bind:ratingStarsClass="ratingStarsClass"
                      v-bind:ratingName="ratingName"></repair-rating>
        <div class="actual-date small text-muted"
             v-if="calcDate && isMobile">
          <a v-on:click="openCalcLogOnActualDateInner()"
             id="actual-date">{{ $t('property.actualDate.default[0]') }} {{ calculationDateInner }} {{ $t('property.actualDate.default[1]') }}</a>
        </div>
      </div>
      <short-property-info-with-comma v-bind:address="address"
                                      v-bind:flatInfo="flatInfoWithUnit"
                                      v-bind:constructionYear="constructionYearInner"
                                      v-bind:materialType="materialTypeInner">
      </short-property-info-with-comma>
    </div>
    <div v-if="type == 'CompetitorsObject'"
         v-on:click="clickEvent">
      <div class="d-flex justify-content-between align-items-start mb-1">
        <div class="object-info">
          <property-icon v-bind:class="iconClass"
                         v-bind:icon="icon"></property-icon>
          <rooms-count v-bind="roomsCountBind"></rooms-count>
          <property-area v-bind="propertyAreaBind"></property-area>
          <building-rating v-bind:buildingRating="buildingRating"
                           sizeClass="sm"
                           v-bind:coordinate="coordinate"></building-rating>
          <noise-area v-bind:inNoiseArea="inNoiseArea"
                      sizeClass="sm"
                      class="noise-area sm mr-3"></noise-area>
        </div>
      </div>

      <short-property-info-with-comma class="mb-2"
                                      v-bind:address="address"
                                      v-bind:flatInfo="flatInfoWithUnit"
                                      v-bind:constructionYear="constructionYearInner"
                                      v-bind:materialType="materialTypeInner">
      </short-property-info-with-comma>
      <div class="columns competitors">
        <div class="grid-col"
             v-if="price">
          <p class="text-muted m-0">{{ $t('property.price') }}</p>
          <p class="value m-0">
            <amount-currency v-bind:code="currency" size="xxs" v-bind:val="price"
                             v-bind:parentTypename="parentTypename"
                             btnCustomClassName="btn-sm"></amount-currency>
          </p>
        </div>
        <repair-rating-col class="grid-col"
                           v-bind:repairRating="repairRatingChangesTo || repairRating"></repair-rating-col>
        <div class="grid-col"
            v-if="price">
          <p class="text-muted m-0" v-html="$t(`property.pricePer.${units.area}`)"></p>
          <p class="value m-0">
            <amount-currency v-bind:code="currency" size="xxs" v-bind:val="price / area"
                             v-bind:parentTypename="parentTypename"
                             btnCustomClassName="btn-sm"></amount-currency>
          </p>
        </div>
        <div class="grid-col"
            v-if="discountVal < 100">
          <p class="text-muted m-0">
            <span v-if="discountText">{{ discountText }}</span>
            <span v-else>{{ $t('property.discountShortV') }}</span>
          </p>
          <p class="value m-0"
            v-if="price">
            {{ formatNumber(Math.abs(discountVal)) }}
            <span class="text-xxs">%</span>
          </p>
          <p v-else>–</p>
        </div>
        <div class="grid-col"
            v-if="scorePowerOfDemand">
          <p class="text-muted m-0 d-flex align-items-center">
            {{ $t('property.demand') }}
            <span v-bind:class="`bar-legend sm ${color} ml-2 mr-1`">
              <span class="bar-legend-demand"
                    v-bind:style="barDemandStyle"></span>
            </span>
            <span class="badge badge-beta ml-1">BETA</span>
          </p>
          {{ scorePowerOfDemand.toFixed(0) }}
          <span class="text-xxs">%</span>
        </div>
        <div class="grid-col"
             v-if="countExpositionDays">
          <p class="text-muted m-0 d-flex align-items-center">
            <span class="text-nowrap">{{ $t('property.exposition.default') }}</span>
            <span class="badge badge-beta ml-1">BETA</span>
          </p>
          <span v-if="countExpositionDays">{{ $tc('timePeriod.day', countExpositionDays) }}</span>
        </div>
        <div class="grid-col" v-if="metaInfo && metaInfo.transactionNumber">
          <p class="text-muted m-0 d-flex align-items-center">
            <span class="text-nowrap">{{ $t('property.metaInfo.transactionNumber') }}</span>
          </p>
          <span>{{ metaInfo.transactionNumber }}</span>
        </div>
        <div class="grid-col" v-if="metaInfo && metaInfo.transactionAt">
          <p class="text-muted m-0 d-flex align-items-center">
            <span class="text-nowrap">{{ $t('property.metaInfo.transactionAt') }}</span>
          </p>
          <span>{{ $d(new Date(metaInfo.transactionAt), 'short') }}</span>
        </div>
      </div>
    </div>
    <div v-if="type == 'CompetitorsMapObject' || type == 'TradeUpMapObject'">
      <div class="mb-2"
           v-if="type == 'CompetitorsMapObject'">
        <div class="d-flex align-items-baseline justify-content-between">
          <div class="competitors-map-object-top-row">
            <property-icon v-bind:class="iconClass"
                           v-bind:icon="icon"></property-icon>
            <short-property-info-with-comma v-bind:address="address"
                                            v-bind:flatInfo="flatInfoWithUnit">
            </short-property-info-with-comma>
          </div>
        </div>
      </div>
      <div class="columns four-cols">
        <div>
          <p class="text-muted m-0">
            {{ $t('property.apartment') }}
          </p>
          <p class="value m-0">
            <rooms-count v-bind="roomsCountBind"></rooms-count>
            <span v-if="roomsCount !== null && area">, </span>
            <property-area v-bind="propertyAreaBind"
                           v-bind:hideDivider="true"></property-area>
            <span v-if="roomsCount == null && !area">{{ $t('property.noData') }}</span>
          </p>
        </div>
        <div v-if="price">
          <p class="text-muted m-0">
            {{ $t('property.price') }}
          </p>
          <p class="value m-0">
            <property-price v-bind="propertyPriceBind"
                            v-bind:hideDivider="true"
                            v-bind:parentTypename="parentTypename"
                            btnCustomClassName="btn-sm"></property-price>
          </p>
        </div>
        <repair-rating-col class="grid-col"
                           v-bind:repairRating="repairRatingChangesTo || repairRating"></repair-rating-col>
        <div>
          <p class="text-muted m-0">
            {{ $t('property.building') }}
          </p>
          <p class="value m-0">
            <span>{{ constructionYearInner }}</span>
            <span v-if="constructionYearInner && buildingRating">, </span>
            <building-rating v-bind:buildingRating="buildingRating"
                             v-bind:sizeClass="sizeClass"
                             v-bind:hideDivider="true"
                             v-bind:short="true"
                             v-bind:coordinate="coordinate"></building-rating>
            <span v-if="!constructionYear && !buildingRating">{{ $t('property.noData') }}</span>
          </p>
        </div>
        <div v-if="flatInfo && type == 'TradeUpMapObject'">
          <p class="text-muted m-0">
            {{ $t('property.flatInfoFloor') }}
          </p>
          <p class="value m-0">
            {{ flatInfoNoUnit }}
          </p>
        </div>
      </div>
      <slot name="trade_up_columns"></slot>
    </div>
  </div>
</template>

<script>
import transliterate from '@/lib/transliterate';
import { openCalcLogOnActualDate } from '@/lib/open_calc_log';
import ObjectBase from '@/components/common/ObjectBase.vue';
import RoomsCount from '@/components/common/property_options/RoomsCount.vue';
import PropertyArea from '@/components/common/property_options/PropertyArea.vue';
import PropertyPrice from '@/components/common/property_options/PropertyPrice.vue';
import ShortPropertyInfoWithComma from '@/components/common/property_options/ShortPropertyInfoWithComma.vue';
import Tags from '@/components/common/property_options/Tags.vue';
import updatePanelData from '@/mixins/updatePanelData';
import BuildingRating from '@/components/common/property_options/BuildingRating.vue';
import NoiseArea from '@/components/common/property_options/NoiseArea.vue';
import ExpositionDays from '@/components/common/property_options/ExpositionDays.vue';
import RepairRating from '@/components/common/property_options/RepairRating.vue';
import RepairRatingCol from '@/components/common/property_options/RepairRatingCol.vue';
import PropertyIcon from '@/components/common/property_options/PropertyIcon.vue';
import isMobile from '@/mixins/isMobile';
import { mapGetters, mapState } from 'vuex';
import Tooltip from '@/components/common/Tooltip.vue';

const BAR_DEMAND_HEIGHT = 18;

export default {
  extends: ObjectBase,
  props: ['type', 'roomsCount', 'area', 'repairRating', 'floorNumber', 'floorsCount', 'address', 'constructionYear', 'materialType', 'tags', 'size', 'price', 'icon', 'iconClass', 'discountText', 'discountVal', 'ratingName', 'objIsHidden', 'ratingStarsClass', 'buildingRating', 'inNoiseArea', 'expositionDays', 'hidePricePerSqMeter', 'repairType', 'units', 'isUnpublished', 'scorePowerOfDemand', 'color', 'exposition', 'calcDate', 'parentTypename', 'coordinate', 'potentialType', 'repairRatingChangesTo', 'metaInfo', 'unpublishedAt'],
  mixins: [updatePanelData, isMobile],
  components: {
    BuildingRating,
    NoiseArea,
    RoomsCount,
    PropertyArea,
    PropertyPrice,
    ShortPropertyInfoWithComma,
    Tags,
    ExpositionDays,
    RepairRating,
    RepairRatingCol,
    PropertyIcon,
    Tooltip,
  },
  methods: {
    transliterateInner(text) {
      return this.$i18n.locale !== 'ru' ? transliterate(text) : text;
    },
    clickEvent() {
      this.$emit('clickEvent');
    },
    openCalcLogOnActualDateInner() {
      openCalcLogOnActualDate(window.location.href, '#actual-date');
    },
  },
  computed: {
    ...mapState('favouriteMap', ['selectArea', 'objectArea', 'selectCurrency']),
    ...mapGetters({
      isDeveloper: 'isDeveloper',
    }),
    flatInfo() {
      if (!this.floorNumber || !this.floorsCount) return false;
      const floorsCount = this.floorNumber > this.floorsCount ? parseInt(this.floorNumber * 1.5, 10) : this.floorsCount;

      return `${this.floorNumber}/${floorsCount}`;
    },
    flatInfoNoUnit() {
      if (!this.flatInfo) return;
      return `${this.flatInfo}`;
    },
    flatInfoWithUnit() {
      if (!this.flatInfo) return;
      return `${this.flatInfo} ${this.$t('property.floor')}`;
    },
    constructionYearInner() {
      if (!this.constructionYear) return;

      return this.$tc('timePeriod.year', 1, { count: this.constructionYear });
    },
    materialTypeInner() {
      if (this.materialType !== null && this.materialType) {
        const materialType = this.materialType;
        const result = this.$t('form.fields.buildingMaterialType.options').filter(obj => obj.code === materialType);
        return result.length > 0 ? result[0].label.toLowerCase() : false;
      }

      return false;
    },
    sizeClass() {
      return this.size || '';
    },
    repairTypeText() {
      if (!this.$constants.REPAIR_TYPE_2.includes(this.repairType)) return;
      return this.$t(`property.repairType2.${this.repairType}`);
    },
    barDemandStyle() {
      return {
        height: BAR_DEMAND_HEIGHT * this.scorePowerOfDemand / 100 + 'px'
      };
    },
    unpublishedLabel() {
      return this.potentialType === 'rent' ? this.$t('property.unlisted') : this.$t('property.sold');
    },
    countExpositionDays() {
      return this.exposition ? this.exposition.days : null;
    },
    buildingRatingInner() {
      return this.buildingRating && this.buildingRating != 'Неизвестно';
    },
    roomsCountBind() {
      return {
        roomsCount: this.roomsCount,
        sizeClass: this.sizeClass
      };
    },
    propertyAreaBind() {
      // eslint-disable-next-line no-nested-ternary
      const convertibleNumber = this.objectArea === 'sqft' && this.selectArea === 'sqm' ? 10.76391042 : this.objectArea === 'sqm' && this.selectArea === 'sqft' ? 0.092903 : 1;
      return {
        units: this.selectArea && (this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap') ? this.selectArea : this.units.area,
        area: this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap' ? Math.round(this.area / convertibleNumber) : this.area,
        sizeClass: this.sizeClass,
        roomsCount: this.roomsCount
      };
    },
    currency() {
      return (this.$route.name === 'FavouriteObjectsMap' || this.$route.name === 'TradeUpMap') && this.selectCurrency ? this.selectCurrency : this.units.currency;
    },
    propertyPriceBind() {
      return {
        currency: this.currency,
        price: this.price,
        sizeClass: this.sizeClass
      };
    },
    mapObjectBadge() {
      if (!this.isUnpublished && !this.countExpositionDays) return;
      const days = this.$tc('timePeriod.day', this.countExpositionDays);
      let label;
      if (this.isUnpublished) {
        const unpublishedAt = this.unpublishedAt ? this.$d(new Date(this.unpublishedAt), 'short') : '';
        label = this.potentialType === 'rent'
          ? `${this.$t('property.unlisted')} ${unpublishedAt}`
          : `${this.$t('property.sold')} ${unpublishedAt}`;
      } else {
        label = this.$t('property.exposition.competitorsMapObject', { count: days });
      }
      return label;
    },
    mapObjectBadgeClass() {
      return [
        'badge ml-2 mt-1',
        {
          'badge-gray-300': this.isUnpublished,
          'badge-secondary': !this.isUnpublished
        }
      ];
    },
    calculationDateInner() {
      return (this.$d(new Date(this.calcDate), 'short')).replace(',', '');
    },
    estimationResultsIsVisible() {
      return !this.isDeveloper || this.$route.name != 'TradeUpCard';
    }
  },
};
</script>

<style lang="scss" scoped>
.columns {
  display: grid;
  line-height: 1.3;
  grid-gap: 1rem;

  &.competitors {
    grid-template-columns: repeat(3, auto);
    padding-top: 0.3rem;
  }

  &.trade-up-columns {
    padding-top: .5rem;
    grid-template-columns: auto;
    margin-right: -3.4rem;

    &.current-object {
      grid-template-columns: 1fr;
      padding-top: .5rem;
    }
  }

  &.four-cols {
    grid-template-columns: repeat(4, auto);
  }

  .value {
    white-space: nowrap;
    font-weight: 600;
  }
}

.competitors-map-object-top-row {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
}

@include media-breakpoint-down(sm) {
  .columns {
    grid-gap: .5rem;
  }
}
</style>
